@import './variables.scss';

@mixin gradient($direction) {
  background-image: linear-gradient(
    to $direction,
    $theme-color-primary,
    $theme-color-secondary
  );
  background-image: -moz-linear-gradient(
    to $direction,
    $theme-color-primary,
    $theme-color-secondary
  );
  background-image: -webkit-gradient(
    linear,
    0% 0%,
    0% 100%,
    from($theme-color-primary),
    to($theme-color-secondary)
  );
  background-image: -webkit-linear-gradient(
    to $direction,
    $theme-color-primary,
    $theme-color-secondary
  );
  background-image: -o-linear-gradient(
    to $direction,
    $theme-color-primary,
    $theme-color-secondary
  );
}
