@import '../../../../themes/variables.scss';

.modal-discard-called {
  .modal-dialog {
    max-width: 650px;
  }
  .modal-header {
    padding-top: 5px;
    padding-bottom: 0;
  }

  h3 {
    color: $gray;
    margin: 0;
    padding: 0;
    text-align: center;
    width: 100%;
    font-size: 22px;
    padding: 0 10px 20px 10px;
  }

  p {
    font-size: 16px;
    width: 100%;
    text-align: center;
    padding: 10px 10px 30px 10px;
  }

  .buttons {
    div {
      padding: 10px;
    }
    button {
      border-radius: 30px;
      min-width: 170px;
      font-size: 16px;
    }
  }
}
