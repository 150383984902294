// Fonts
$font-family: 'Branding';

// Colors
$primary: #286db8;
$primary-hover: #215a99;
$primary-selecionado: rgba(40, 109, 184, 0.5);
$primary-light: #7daee3;
$danger: #dc3545;
$sucess: #28a745;
$sucess-light: lightgreen;
$secondary: #707070;
$theme-color-primary: #92278f;
$theme-color-secondary: #2d2f93;
$turn-off-the-light: rgba(0, 0, 0, 0.5);
$turn-off-the-lighter: rgba(0, 0, 0, 0.1);

$white: #fff;
$black: #000;
$black2: #00000029;
$white-half-opacity: rgba(255, 255, 255, 0.8);
$gray: #707070;
$gray-light: #e6e6e6;
$gray-lighter: #fafafa;

$amountOfLight: 10%;
$primary-dark: darken($primary, $amountOfLight);
