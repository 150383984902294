@import '../../../../themes/variables.scss';
.onoff-midias-enviadas {
  .table-size{
    max-height: 400px;
    overflow: auto;
  }

  .arquivo-size{
    max-height: 400px;
    max-width: 90%;
    overflow: auto;
  }

  h1.onoff-title {
    text-align: center;
    font-size: 63px;
    color: $theme-color-secondary;
    padding-top: 64px;
    padding-bottom: 32px;
    border-bottom: 1px solid;
    border-bottom-color: $gray;
  }

  div.onoff-content {
    width: 1150px;
    // border-color: black;
    // border-width: 1px;
    // border-style: solid;
    .mes-info {
      text-align: center;
      font-size: 30px;
    }
    .row {
      padding-left: 20px;
      padding-right: 20px;

      label {
        margin-top: 20px;
      }

      textarea {
        border-radius: 20px;
      }
    }
    .text-size-info {
      float: right;
      color: $gray;
      font-size: 14px;
    }
  }

  .on-off-nav-btn {
    margin-top: 30px;
  }

  .onoff-button {
    color: $white;
    background-color: $primary;
    border-radius: 40px;
    margin: 10px !important;
    font-size: 18px;
    font-weight: 500;
    width: 320px;
    height: 64px;
    line-height: 28px;
  }

  .onoff-left {
    float: left;
  }

  .onoff-right {
    float: right;
    margin-right: 0px;
  }

  div.input-group.onoff {
    padding-right: 15 px;
  }

  div.onoff-info {
    padding-left: 32px;
    font-size: 25px;
  }

  hr.onoff {
    margin: 15px;
  }

  a.onoff-navbtns div {
    cursor: pointer;

    flex: 1;
    //margin: 10px 0 10px 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .onoff-help-link {
    display: block;
    padding-bottom: 60px;
  }

  .midias-label {
    font-size: 25px;
    font-weight: 500;
    text-align: center;
    margin-top: 50px;
  }

  .onoff-img {
    padding: 5px;
    width: 250px;
  }
}
