@import '../../themes/variables.scss';
.card-artes {
  .mb-modal {
    position: fixed;
    background-color: $turn-off-the-light;
    width: auto;
    height: auto;
    min-width: 100%;
    min-height: 100%;
    z-index: 99999;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    .mb-modal-body {
      position: relative;
      background-color: $white;
      width: 100%;
      height: 542px;
      max-width: 1200px;
      padding: 20px;
      border-radius: 20px;
      display: flex;
      align-items: center;

      .thumb-produto {
        display: flex;
        flex: 3;
        height: 500px;
        justify-content: center;
        align-items: center;
        img {
          width: 100%;
        }
      }
      .descricao-produto {
        flex: 4;
        height: 500px;
        padding: 20px;
      }
    }
  }
}
