@import '../../../../themes/variables.scss';
h1.onoff-title{
  text-align: center;
  font-size: 63px;
  color: $theme-color-secondary;
  padding-top: 64px;
  padding-bottom: 32px;
  border-bottom: 1px solid;
  border-bottom-color: $gray;
}

div.onoff-content{
  width: 1150px;
  // border-color: black;
  // border-width: 1px;
  // border-style: solid;
  .mes-info{
    text-align: center;
    font-size: 30px;
  }
  .row{
    padding-left: 20px;
    padding-right: 20px;

    label{
      margin-top: 20px;
    }

    textarea{
      border-radius: 20px;
    }
  }
  .text-size-info{
    float: right;
    color: $gray;
    font-size: 14px;
  }
}

.on-off-nav-btn{
  margin-top: 30px;
}

.onoff-button{
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  color: $white;
  background-color: $primary;
  border-radius: 25px;
  margin: 10px;
  margin-top: 50px !important;
  margin-bottom: 50px !important;
  font-size: 18px;
  font-weight: 500;
  width: 320px;
  height: 64px;
  line-height: 28px;
}

.onoff-left{
  float: left;
}

.onoff-right{
  float: right;
  margin-right: 0px;
}

div.input-group.onoff{
  padding-right: 15 px;
}

div.onoff-info{
  padding-left: 32px;
  font-size: 25px;
}

hr.onoff{
  margin: 15px;
}

a.onoff-navbtns div{
    cursor: pointer;

  flex: 1;
  //margin: 10px 0 10px 0;
  display: flex;
  justify-content: center;
  align-items: center;

}

.onoff-help-link{
  display: block;
  padding-bottom: 60px;
}

.midias-label{
  font-size: 25px;
  font-weight: 500;
  text-align: center;
  margin-top: 50px;
}
