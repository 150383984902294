@import '../../themes/variables.scss';

.modal-solicitar-novamente {
  button {
    border-radius: 30px;
    min-width: 120px;
    font-size: 16px;
    width: 100%;
  }
}
