@import '../../../../../themes/variables.scss';

.wrapper-content-alterar-solicitacao-entrega {
  .content-alterar-solicitacao-entrega {
    position: relative;
    max-width: 1024px;
    margin: auto;

    .entrega-fisica-panel {
      border: 1px solid lightgray;
      padding: 20px;
      margin-top: 20px;
      margin-bottom: 20px;
      border-radius: 5px;
    }
    .forma-entrega {
      font-size: 20px;
      flex-direction: row;
      justify-content: space-around;
      display: flex;

      .forma-entrega-opcao {
        .btn {
          border-radius: 20px;
          height: 80px;
          margin: 5px;
          box-shadow: 0px 3px 6px #00000029;
          width: 260px;
        }
      }
    }
  }
}
