@import '../../../../themes/variables.scss';
.content-recover-password {
  .error-message {
    color: $danger;
  }

  label {
    text-align: center;
    font-size: 20px;
  }

  button {
    border-radius: 30px !important;
    text-transform: uppercase;
    font-weight: 500;
  }
}
