@import '../../../../../themes/variables.scss';
@import '../../../../../themes/placeholders.scss';

.off-light {
  @extend %full-screen;
  @extend %flex-content-center;
  background-color: $turn-off-the-light;

  .mm-modal {
    background-color: $white;
    width: 100%;
    opacity: 1;
    border-radius: 20px;
    position: relative;
    .mm-header-modal {
      width: 100%;
      background-color: $primary;
      color: $white;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-transform: uppercase;
      padding: 23px;
      padding-bottom: 16px;
    }
    .mm-header-close {
      display: block;
      position: absolute;
      width: 100%;
      padding: 18px;
      text-align: right;
      top: -10px;
      font-size: 22px;
      svg {
        cursor: pointer;
      }
    }
    .mm-body-modal {
      padding: 40px;
      padding-bottom: 0;
      overflow: auto;
    }
    .mm-footer-modal {
      width: 100%;
      bottom: 0;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding: 30px;
      button {
        border-radius: 30px;
        font-size: 1.2rem;
        text-transform: uppercase;
        padding: 5px 20px;
        border: none;
        background-color: $primary;
        color: $white;
      }
    }
  }
}
