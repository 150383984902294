@import '../../../themes/variables.scss';

.wrapper-chat-chamado {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;

  .conversa::-webkit-scrollbar {
    width: 10px;
    height: 30px;
  }

  .conversa::-webkit-scrollbar-thumb {
    background-color: $gray-light;
    border-radius: 20px;
  }
  .conversa::-webkit-scrollbar-thumb:hover {
    background-color: $gray-light;
    border-radius: 20px;
    transition: cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }
  .conversa::-webkit-scrollbar-button {
    display: none;
  }
  svg{
    margin-top: 6px;
  }
  .contents {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 25px;

    .conversa {
      display: block;
      flex-direction: column-reverse;
      max-height: 500px;
      overflow-y: auto;
      margin-bottom: 20px;
      border-radius: 10px;
      padding: 10px;
      background-color:$gray-light;
      box-shadow: 0px 3px 6px $black2;
    }

    .mensagem {
      padding: 0;

      textarea {
        height: 120px;
        background: $white 0% 0% no-repeat padding-box;
        border-radius: 20px;
        opacity: 1;
        outline: 0;
        box-shadow: 0px 3px 6px $black2;
        border:2px solid $gray;
        resize: none;
      }
      textarea:focus {
        background-color: $white;
        border-color: $primary-light;
        outline: 0;
        box-shadow: 0 0 0 0.2rem $primary;
      }
    }

    .responder {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin: 10px;
      margin-bottom: 0;

      .btnResponder {
        .botao {
          background: var(--unnamed-color-28b83b) 0% 0% no-repeat padding-box;
          background: $sucess 0% 0% no-repeat padding-box;
          box-shadow: 0px 3px 6px $black2;
          border-radius: 30px;
          border-color: transparent;
          height: 42px;
          width: 150px;
          padding-top: 8px;
          .icon {
            margin-right: 10px;
          }
        }

        .botao:hover {
          background: var(--unnamed-color-005d13) 0% 0% no-repeat padding-box;
          background: $sucess 0% 0% no-repeat padding-box;
          box-shadow: 0px 3px 6px $black2;
        }
      }

      .input {
        background: var(--unnamed-color-286db8) 0% 0% no-repeat padding-box;
        border: 1px solid var(--unnamed-color-286db8);
        background: $primary 0% 0% no-repeat padding-box;
        border: 1px solid $primary;
        border-radius: 5px;
        color: $white;
        padding-left: 10px;
        padding-right: 10px;
        display: flex;
        justify-content: center;
        cursor: pointer;
        height: 42px;
        margin: 0;
      }
      input[type='file'] {
        display: none;
      }
    }

    .voltar {
      margin-bottom: 30px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .btnVoltar {
        background: var(--unnamed-color-286db8) 0% 0% no-repeat padding-box;
        background: $primary 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px $black2;
        border-radius: 30px;
        height: 42px;
        width: 150px;
        padding-top: 5px;
      }

      Button {
        flex-direction: row;
        background: var(--unnamed-color-286db8) 0% 0% no-repeat padding-box;
        background: $primary 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px $black2;
        border-radius: 30px;
        width: 150px;
        height: 42px;

        .icon {
          margin-top: 8px;
          margin-right: 10px;
        }
      }
      Button:hover,
      .btnVoltar:hover {
        background: $primary-hover 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px $black2;
      }
    }

    @media only screen and (max-width: 577px) {
      .motivo {
        display: flex;
        text-align: center;
        flex-direction: column;
        align-content: center;
      }

      .responder {
        Button {
          max-width: 150px;
        }

        label {
          text-align: center;
        }
      }
    }
    @media only screen and (max-width: 480px) {
      .voltar,
      .responder {
        display: block;
        text-align: center;
      }

      .voltar {
        .btnVoltar {
          background: var(--unnamed-color-286db8) 0% 0% no-repeat padding-box;
          background: $primary 0% 0% no-repeat padding-box;
          box-shadow: 0px 3px 6px $black2;
          border-radius: 30px;
          height: 42px;
          width: 150px;
          padding-top: 8px;
        }
      }
      .responder {
        Button {
          max-width: 150px;
          div {
            margin-bottom: 0;
          }
        }

        label {
          text-align: center;
        }
        .arquivo {
          max-width: 150px;
          text-align: center;
          flex-direction: row;
          justify-content: center;
          display: inline-block;
        }
      }
      .mensagem {
        margin-bottom: 0;
      }
    }
  }
}
