@import '../../../../themes/variables.scss';

.table-rascunho {
  max-width: 1600px;
  margin: 0 auto;
  padding: 15px;

  th {
    font-weight: 900;
    border-top: none;
  }

  td {
    padding: 0.5rem;
  }

  .table tbody tr:nth-of-type(even) {
    background-color: white;
  }

  .table-button {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    border-radius: 30px;
    min-width: 140px;
    font-size: 20px;
    height: 36px;
    padding-top: 2px;
  }

  ul.pagination{
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;

    li a{
      padding-top: 12px;
    }
  }
}
