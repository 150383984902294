@import '../../../themes/variables.scss';

.wrapper-content-pedido {
  .sub-header {
    display: none;
  }
  .content-pedido-default {
    position: relative;
    min-height: calc(100vh - 120px);
    padding: 30px;
    margin: auto;

    @media screen and (min-width: 322px) {
      min-height: calc(100vh - 140px);
    }
  }
  .content-pedido-default {
    @media screen and (max-width: 768px) {
      .nome-material {
        text-align: center !important;
      }
    }
  }

  .btn-link {
    padding-left: 0;
    padding-right: 0;
    box-shadow: none;
  }
  .btn-link:focus,
  .btn-link.focus {
    box-shadow: none;
  }

  .btn-link:hover {
    background-color: transparent;
  }

  .btn-disabled {
    background-color: $gray !important;
    border-color: $gray !important;
    cursor: not-allowed !important;
  }

  .btn-navigation {
    width: 200px;
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    white-space: nowrap;

    span {
      display: flex;
    }

    span {
      &:nth-of-type(1) {
        flex: 1;
        justify-content: center;
      }
      &:nth-of-type(2) {
        flex: 3;
        justify-content: center;
      }
    }
  }

  .btn-navigation-right {
    span {
      &:nth-of-type(1) {
        flex: 3;
        justify-content: center;
      }
      &:nth-of-type(2) {
        flex: 1;
        justify-content: center;
      }
    }
  }

  .btn-navigation-xs {
    width: auto;
    font-size: 14px;
  }

  .btn-material {
    color: $white;
    width: 100%;
    margin-bottom: 10px;
  }

  .btn-material-success {
    background: $sucess;
  }

  .btn-material-danger {
    background: $danger;
  }

  .input-with-icon {
    display: flex;

    input {
      width: 100%;
      height: 55px;
      border: 1px solid $primary;
      border-top-left-radius: 30px;
      border-bottom-left-radius: 30px;
      border-right: none;
      outline: none;
      text-indent: 20px;
      background-color: transparent;
    }

    .icon {
      background-color: $primary;
      width: 65px;
      height: 55px;
      display: flex;
      border-top-right-radius: 30px;
      border-bottom-right-radius: 30px;
      justify-content: center;
      align-items: center;
      color: $white;
      font-size: 1.3rem;
      &:hover {
        cursor: pointer;
      }
    }
  }

  .react-datepicker-wrapper {
    flex: 1 1 auto !important;
  }
  .lista-materiais {
    border: 1px solid $gray-light;
    border-radius: 20px;
    margin: 20px 0;

    .header-card {
      background-color: $primary;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-transform: uppercase;
      color: $white;
      text-align: center;
    }
    .body-card {
      height: 350px;

      ul {
        padding: 0;
        list-style: none;
        margin-top: 20px;
        input[type='checkbox'] {
          display: none;
          &:checked ~ label li {
            background-color: $primary;
            color: $white;
          }
        }
        label {
          width: 100%;
          margin: 2px 0;

          li {
            color: $gray;
            padding: 5px 0 5px 20px;
            text-align: left;
          }
        }
      }
    }
  }

  .lista-campanha-materiais {
    border: 1px transparent $gray-light;
    border-radius: 20px;
    margin: 40px 0;
    cursor: pointer !important;
    box-shadow: 0px 3px 6px $black2;
    max-width: 255px;
    min-width: 240px;
    margin: 10px;

    .header-card {
      background-color: $secondary;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-transform: uppercase;
      color: $white;
      text-align: center;

      i {
        position: absolute;
        left: 24px;
        color: $sucess-light;
      }
    }

    .header-card.selecionado {
      background-color: $primary;
    }

    .body-card {
      input[type='checkbox'] {
        display: none;
      }

      label {
        width: 100%;
        margin: 0;
        cursor: pointer;

        img {
          border: 1px transparent;
          border-bottom-left-radius: 20px;
          border-bottom-right-radius: 20px;
        }

        li {
          color: $gray;
          padding: 5px 0 5px 20px;
          text-align: left;
        }
      }
    }
  }
  .lista-campanha-materiais:hover {
    box-shadow: 0 0 0 0.2rem $primary;
  }
  .campo-obrigatorio {
    border: 1px solid $danger !important;
    border-radius: 0.25rem;
  }
}
