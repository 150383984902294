@import '../../themes/variables.scss';
.wrapper-atendimento {
  .title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: var(--unnamed-color-286db8) 0% 0% no-repeat padding-box;
    background: $primary 0% 0% no-repeat padding-box;
    border-radius: 20px 20px 0px 0px;
    height: 50px;
    color: $white;
    font-size: 25px;
    padding-left: 30px;
  }

  .atendimento {
    background-color: $white;
    font-weight: 800;
    padding-top: 20px;
    padding-bottom: 20px;
    background: $white 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px $black2;
    border-radius: 0px 0px 20px 20px;
    padding: 20px;

    .download a {
      height: 100px;
      color: $white;
      list-style: none;
    }
    .download Button {
      border-radius: 20px;
      box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    }

    form {
      display: flex;
      flex-direction: column;

      .row {
        span {
          font-weight: 300;
          text-align: start;
        }
      }
    }
  }

  @media only screen and (max-width: 768px) {
    .atendimento form {
      .row {
        text-align: center;

        span {
          text-align: center;
        }
      }
    }
  }
}
