@import '../../../themes/variables.scss';
@import '../../../themes/mixins.scss';

.header-logo-only {
  width: 100%;
  height: 90px;
  @include gradient(right);
  display: flex;
  text-align: center;

  @media screen and (min-width: 321px) {
    height: 90px;
  }

  .content-brand {
    margin: 0 auto;

    img {
      margin-top: 20px;
      max-width: 95px;

      @media screen and (min-width: 321px) {
        max-width: 100px;
      }
    }
  }
}
