@font-face {
  font-family: 'Branding';
  src: url('../assets/fonts/Branding-Black.otf');
  font-style: normal;
  font-weight: 900;
}

@font-face {
  font-family: 'Branding';
  src: url('../assets/fonts/Branding-BlackItalic.otf');
  font-style: italic;
  font-weight: 900;
}

@font-face {
  font-family: 'Branding';
  src: url('../assets/fonts/Branding-Bold.otf');
  font-style: normal;
  font-weight: bold;
}

@font-face {
  font-family: 'Branding';
  src: url('../assets/fonts/Branding-BoldItalic.otf');
  font-style: italic;
  font-weight: bold;
}

@font-face {
  font-family: 'Branding';
  src: url('../assets/fonts/Branding-Light.otf');
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: 'Branding';
  src: url('../assets/fonts/Branding-LightItalic.otf');
  font-style: italic;
  font-weight: 300;
}

@font-face {
  font-family: 'Branding';
  src: url('../assets/fonts/Branding-Medium.otf');
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: 'Branding';
  src: url('../assets/fonts/Branding-MediumItalic.otf');
  font-style: italic;
  font-weight: 400;
}

@font-face {
  font-family: 'Branding';
  src: url('../assets/fonts/Branding-SemiBold.otf');
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: 'Branding';
  src: url('../assets/fonts/Branding-SemiBoldItalic.otf');
  font-style: italic;
  font-weight: 500;
}

@font-face {
  font-family: 'Branding';
  src: url('../assets/fonts/Branding-SemiLight.otf');
  font-style: normal;
  font-weight: 200;
}

@font-face {
  font-family: 'Branding';
  src: url('../assets/fonts/Branding-SemiLightItalic.otf');
  font-style: italic;
  font-weight: 200;
}

@font-face {
  font-family: 'Branding';
  src: url('../assets/fonts/Branding-Thin.otf');
  font-style: normal;
  font-weight: 100;
}

@font-face {
  font-family: 'Branding';
  src: url('../assets/fonts/Branding-ThinItalic.otf');
  font-style: italic;
  font-weight: 100;
}
