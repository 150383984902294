@import '../../../../../themes/variables.scss';

.modal-solicitacaofluxo-produtos {
  .modal-dialog {
    max-width: 1000px;
    font-weight: 200;
  }

  .row-produto {
    background: $gray-light;
    padding: 10px;

    label {
      font-weight: bold;
      display: block;
      margin: 0;
    }

    .preco-atual {
      color: $gray;
      cursor: not-allowed;
    }
  }

  button {
    border-radius: 30px;
    font-size: 18px;
    text-transform: uppercase;
    padding: 9px 30px;
    border: none;
  }

  .grid-solicitacao-produtos {
    padding: 0 15px;
    font-weight: 200;
    font-size: 14px;

    .grid-row-produto {
      border-top: 1px solid $gray-light;
      padding: 0px 5px 5px 0px;
      .image-container {
        text-align: center;
        img {
          max-height: 255px;
        }
      }
    }

    .grid-row-produto:nth-child(even) {
      background-color: $white;
    }
    .grid-row-produto:nth-child(odd) {
      background-color: $gray-light;
    }

    label {
      display: block;
      margin-bottom: 0;
      font-size: 14px;
      font-weight: 500;
    }

    .produto-error {
      border: 1px solid $danger;
    }
  }

  .table-search-produtos-wrapper {
    height: 300px;
    overflow: auto;

    /* Set header to stick to the top of the container. */
    thead tr th {
      position: sticky;
      top: 0;
      background: $white;
      border-top: $white;
    }

    .table-search-produtos tr {
      font-size: 14px;
      font-family: sans-serif;
    }

    td{
      font-size: 12px !important;
    }
    .btn-navigation {
      width: 200px;
      border-radius: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 500;
      white-space: nowrap;
  
      span {
        display: flex;
      }
  
      span {
        &:nth-of-type(1) {
          flex: 1;
          justify-content: center;
        }
        &:nth-of-type(2) {
          flex: 3;
          justify-content: center;
        }
      }
    }
  
    .btn-navigation-right {
      span {
        &:nth-of-type(1) {
          flex: 3;
          justify-content: center;
        }
        &:nth-of-type(2) {
          flex: 1;
          justify-content: center;
        }
      }
    }
  
    .btn-navigation-xs {
      width: auto;
      font-size: 12px;
      padding: 5px 20px;
    }
  }
}
