@import '../../../../../../../themes/variables.scss';

.modal-busca-produto-inovacao {
  font-size: 14px;

  .modal-dialog {
    max-width: 1000px;
  }

  .modal-header {
    background-color: $primary;
    color: $white;
    text-transform: uppercase;
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;

    .modal-title {
      text-align: center;
      width: 100%;
    }

    button span {
      color: $white;
    }
  }

  .modal-body {
    font-size: 14px;
    min-height: 200px;
    max-height: 700px;
    overflow: auto;
  }

  .modal-footer {
    text-align: right;

    .button-close {
      border-radius: 30px;
      min-width: 130px;
      font-size: 14px;
      margin-right: 30px;
    }
  }

  .table-search-produtos{
    font-size: 14px;

    tr {
      font-size: 14px;
      cursor: pointer;
    }

    .th:hover {
      background-color: $primary-light !important;
    }

    .th {
      font-weight: 500;
      border-top: none;
    }

    .tr-selecionado {
      background-color: $primary !important;
      color: $white !important;
    }
  }

  .btn-add-inovacao{
    width: 130px !important;
    font-size: 14px;
  }
}
