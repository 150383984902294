@import '../../../themes/_variables.scss';

.modal-aprovacao {
  .modal-dialog {
    max-width: 800px;
  }

  .header {
    padding-left: 80px;

    .close {
      font-size: 30px;
      font-weight: 900;
      color: $primary !important;
      opacity: 1;
    }
  }

  .modal-footer {
    text-align: center;
    padding-right: 80px;

    .button{
      box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
      border-radius: 10px;
      min-width: 120px;
      font-size: 16px;
    }
    .btn{
      box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    }
  }
}
