@import '../../../../../themes/variables.scss';

.wrapper-content-pedido-entrega-fisica {
  .content-pedido-entrega-fisica {
    position: relative;
    min-height: calc(100vh - 120px);
    max-width: 1024px;
    margin: auto;

    .texto {
      padding: 30px;
      text-align: center;
      font-size: 21px;
      font-weight: 600;
    }

    .mb-5 {
      align-items: center;
      flex-flow: wrap;
      justify-content: center;
      display: flex;

      .btn-danger {
        border-radius: 20px;
        padding-left: 30px;
        padding-right: 30px;
      }
      .mb-3 {
        text-align: center;

        .btn {
          box-shadow: 0px 3px 6px $black2;
          margin: 5px;
          padding-left: 30px;
          padding-right: 30px;
          border-radius: 20px;
        }
      }
    }

    .forma-entrega {
      font-size: 20px;
      flex-direction: row;
      justify-content: space-around;
      display: flex;

      .forma-entrega-opcao {
        .btn {
          border-radius: 20px;
          height: 80px;
          margin: 5px;
          box-shadow: 0px 3px 6px $black2;
          width: 260px;
        }
      }
    }
    .entrega-fisica-panel {
      .dados {
        .loja {
          margin-bottom: 25px;
          padding-left: 8px;
          padding-right: 8px;

          .panel-default {
            .panel-heading {
              color: $white;
              background-color: $primary;
              text-align: center;
              margin: 0;
              border-top-left-radius: 5px;
              border-top-right-radius: 5px;
              font-size: 25px;
            }
            .panel-body {
              min-height: 580px;
              padding: 5px;
              border: 1px solid;
              border-bottom-right-radius: 5px;
              border-bottom-left-radius: 5px;
              border-top: 0;

              tbody tr {
                font-size: 17px;
              }
            }
          }
        }
      }
    }
    .row-buttons {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .flex-row-reverse {
        display: flex;
        align-items: flex-end;
      }
    }
    @media screen and (min-width: 322px) {
      min-height: calc(100vh - 140px);
    }
    @media screen and (max-width: 480px) {
      .row-buttons {
        display: grid;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .flex-row-reverse {
          display: block;
        }

        button {
          margin: 5px;
        }
      }
    }
    @media screen and (max-width: 700px) {
      .entrega-fisica-panel {
        .dados {
          flex-direction: column;
        }
      }
    }
    .entrega-fisica-panel {
      border: 1px solid $gray-light;
      padding: 20px;
      margin-top: 20px;
      margin-bottom: 20px;
      border-radius: 5px;
    }
  }
}
