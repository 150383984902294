@import '../../themes/variables.scss';

.mm-card {
  border: 1px solid $gray-light;
  height: 300px;

  border-radius: 20px;
  margin: 20px;
  width: 360px;

  .header-card {
    background-color: $primary;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    color: $white;
  }
  .body-card {
    height: 250px;
    ul {
      padding: 0;
      list-style: none;
      margin-top: 20px;
      input[type='checkbox'] {
        display: none;
        &:checked ~ label li {
          background-color: $primary;
          color: $white;
        }
      }
      label {
        width: 100%;
        margin: 2px 0;
        li {
          color: $gray;
          padding: 5px 0 5px 20px;
          text-align: left;
        }
      }
    }
  }
}
